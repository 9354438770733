<template>
	<div class="container">
		<img class="title-img" src="../../assets/imgs/wisdomFactory.png" alt="">
		<div class="content">
			<div class="text">
				<div class="info">
					<p class="title">智能制造工厂</p>
					<p class="cont">天乾地坤总投资5000万，已完成投资2000万，在河北·承德·围场满族蒙古族自治县四合永营字村工业园区投资建设绿色低碳智能制造共享工厂，建筑面积3400平米，使用面积10200平米，主要用于生产超低能耗核酸采样室、小门神、护眼灯、智能锁、智能家居、喂食器、5G智慧灯杆、电子哨兵、摄像头、无人机、智慧农业水费一体机等。</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		
	}
</script>

<style scoped>
.title-img {
	width: 100%;
	height: 519px;
}

.content {
	width: 1200px;
	margin: 0 auto;
	box-sizing: border-box;
	padding: 45px 75px 136px;
}

.text {
	background-image: url('../../assets/imgs/wisdomFactory1.png');
	background-size: cover;
	width: 1064px;
	height: 392px;
	position: relative;
}

.info {
	position: absolute;
	top: 50%;
	right: 80px;
	transform: translate(0, -50%);
}
.cont {
	width: 510px;
	font-size: 14px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #666666;
}

.title {
	font-size: 24px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333;
	margin-bottom: 15px;
}

</style>